<template>
  <div class="gallery__wrapper">
    <div
      class="gallery__main-image"
      ref="main_image"
      itemprop="image"
    >
      <div v-if="images.length" class="gallery__changer gallery__changer--prev" @click="switch_image(selected_img_index - 1)" />
      <zoom
        :zoom="get_zoom_image"
        :regular="main_img"
        :alt="product_name"
        :zoom-amount="2"
        class="gallery__zoom"
        :hover-message="translations.hover_to_zoom || $translate('products.hover_to_zoom')"
        :touch-message="translations.touch_to_zoom || $translate('products.touch_to_zoom')"
      />
      <div v-if="images.length" class="gallery__changer gallery__changer--next" @click="switch_image(selected_img_index + 1)" />
    </div>
    <div class="gallery__sub__wrapper">
      <div class="gallery__sub-images">
        <v-lazy-image
          v-for="({ src, thumb_src, alt_text }, index) in images"
          :key="`small-image-${index}`"
          :src="get_image_src({ src, thumb_src })"
          :src-placeholder="default_image_src"
          class="gallery__sub-image"
          itemprop="image"
          :alt="alt_text || product_name"
          @click.native="switch_image(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import zoom from "vue-image-zoomer"
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js"
import { image_dimension_names } from "../../../../data/other_constants"
import { default_image_src, SHARED_STORE } from "../../constants/other"
import { CLIENT_STORE } from "../../../Client/constants/other"
import { event_hub } from "../../../../main"
import get_image_src from "../../methods/get_image_src"

export default {
  props: {
    images: {
      type: Array,
      required: true
    },
    product_name: {
      type: String,
      required: true
    },
  },
  components: {
    zoom,
    VLazyImage
  },
  data() {
    return {
      selected_img_index: 0,
      main_img: default_image_src,
      default_image_src,
      image_dimension_names
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_device",
      "is_mobile_resolution"
    ]),
    ...mapState(CLIENT_STORE, [
      "user_is_using_fast_connection",
      "translations",
      "use_shopify_data"
    ]),
    get_zoom_image() {
      return this.get_image_src(
        this.images[this.selected_img_index],
        this.get_correct_image_dimension(true)
      )
    }
  },
  watch: {
    images() {
      this.initial_image_load()
    },
  },
  mounted() {
    this.initial_image_load()
    event_hub.$on(
      "user_changed_variation",
      ({ image_id }) => this.switch_image(this.images.findIndex(({ id }) => image_id === id))
    )
  },
  methods: {
    get_image_src,
    get_correct_image_dimension(get_zoom_image) {
      return this.user_is_using_fast_connection ?
          // On fast connection
          image_dimension_names[get_zoom_image ? "desktop" : "tablet"] :
          // On slow connection
          image_dimension_names[get_zoom_image ? "tablet" : "mobile"]
    },
    async initial_image_load() {
      this.main_img = get_image_src(
        this.images[0],
        this.get_correct_image_dimension()
      )
      this.selected_img_index = 0
    },
    async switch_image(index) {
      const get_selected_id = index > -1 ? (index > this.images.length - 1 ? 0 : index) : this.images.length - 1

      if (get_selected_id === this.selected_img_index) return 

      const selected_image = this.images[get_selected_id]
      const correct_image_src = get_image_src(
        selected_image,
        this.get_correct_image_dimension()
      )

      this.main_img = correct_image_src
      this.selected_img_index = get_selected_id
    }
  }
};
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;

.gallery {
  &__wrapper {
    position: sticky;
    display: inline-block;
    top: $menu-height + 20px;
    width: 50%;
    padding: 10px;
    border-radius: $border-radius;
    background: $pure_white;
    box-sizing: border-box;
    overflow: hidden;

    @media (max-width: $tablet--small) {
      position: relative;
      display: block;
      top: 0;
      width: 100%;
      padding: 0;
      max-width: 100%;
      box-sizing: border-box;
    }
  }

  &__main-image {
    position: relative;
    border-radius: $border-radius;
    overflow: hidden;

    @media (max-width: $tablet--small) {
      border-radius: unset;
    }
  }

  &__sub {
    &__wrapper {
      max-height: 100px;
      overflow-y: scroll;
      margin-top: 10px;
    }

    &-images {
      display: flex;
      gap: 5px;
      padding: 5px 0;

      @media (max-width: $tablet--small) {
        padding: 5px;
      }
    }

    &-image {
      height: 90px;
      width: 90px;
      display: inline-block;
      object-fit: cover;
      border-radius: $border-radius;
      cursor: pointer;
    }
  }

  &__changer {
    position: absolute;
    top: calc(50% - 20px);
    right: 0;
    background-color: $transparency-white-color;
    height: 40px;
    width: 35px;
    background-image: url(/static/icons/arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 8px;
    z-index: 2;
    opacity: 0.7;
    cursor: pointer;
    transition: $default-transition;
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
    overflow: hidden;

    &--prev {
      left: 0;
      transform: rotate(180deg);
    }
  }

  &__loading {
    position: absolute;
    background: $transparency-color;
    width: 100%;
    height: 100%;
    box-shadow: none;
    color: transparent;
    left: 0;
    z-index: 2;
    text-shadow: none;

    img {
      position: absolute;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      width: 50px;
      height: 50px;
      box-shadow: none;
      animation: permanent-spin 3s infinite linear;
    }
  }

  &__zoom {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: 700px;

    picture:first-child img {
        width: 100%;
        height: 100%;
        max-height: 700px;
        object-fit: contain;
    }
  }
}

@keyframes permanent-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
